import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTruck, faBox } from "@fortawesome/free-solid-svg-icons";
import "./CompanyStat.styles.css";

const icons = {
  faStar,
  faTruck,
  faBox,
};

const CompanyStat = ({ icon, label }) => {
  return (
    <div className="stat-wrapper">
      <FontAwesomeIcon icon={icons[icon]} className="stat-icon" />
      <span className="stat-label">{label}</span>
    </div>
  );
};

export default CompanyStat;

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faPhone,
  faKey,
  faTruck,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import "./Registration.css";
import Header from "../../pages/Header";
import { useAuth } from "../AuthContext/AuthContext";

const RegistrationForm = () => {
  const { enqueueSnackbar } = useSnackbar();
const initialState = {
  companyName: "",
  username: "",
  password: "",
  confirmPassword: "",
  companyAddress: "",
  companyEmail: "",
  phoneNumber: "",
  companyType: "",
  dotOrMcNumber: "",
  registrationType: "",
};


  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { handleCompanyRegister } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  // Frontend validation
  if (!formData.companyName || !formData.username || !formData.password) {
    enqueueSnackbar("All fields are required.", { variant: "error" });
    return;
  }

  if (formData.password !== formData.confirmPassword) {
    enqueueSnackbar("Passwords do not match.", { variant: "error" });
    return;
  }

  setLoading(true);

  try {
    await handleCompanyRegister(formData);
    console.log("Submitting Registration Data:", formData);

    enqueueSnackbar("Registration successful!", { variant: "success" });
    setFormData(initialState); 
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || "Registration failed. Please try again.";
    enqueueSnackbar(errorMessage, { variant: "error" });
    console.error("Registration Error:", errorMessage);
  } finally {
    setLoading(false);
  }
};


  return (
    <div>
      <Header title="Company Registration" />
      <motion.div
        className="registration-container"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ type: "spring", duration: 0.5 }}
      >
        <h2>Company Registration</h2>
        <form onSubmit={handleSubmit} className="registration-form">
          {/* Company Name */}
          <div className="register-form-group">
            <label htmlFor="companyName">
              <FontAwesomeIcon icon={faBuilding} /> Company Name
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              placeholder="Enter your company name"
            />
          </div>

          {/* Username */}
          <div className="register-form-group">
            <label htmlFor="username">
              <FontAwesomeIcon icon={faIdCard} /> Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              placeholder="Enter your username"
            />
          </div>

          {/* Password */}
          <div className="register-form-group">
            <label htmlFor="password">
              <FontAwesomeIcon icon={faKey} /> Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Enter your password"
            />
          </div>

          {/* Confirm Password */}
          <div className="register-form-group">
            <label htmlFor="confirmPassword">
              <FontAwesomeIcon icon={faKey} /> Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              placeholder="Confirm your password"
            />
          </div>

          {/* Company Address */}
          <div className="register-form-group">
            <label htmlFor="companyAddress">
              <FontAwesomeIcon icon={faBuilding} /> Company Address
            </label>
            <input
              type="text"
              id="companyAddress"
              name="companyAddress"
              value={formData.companyAddress}
              onChange={handleChange}
              required
              placeholder="Enter your company address"
            />
          </div>

          {/* Company Email */}
          <div className="register-form-group">
            <label htmlFor="companyEmail">
              <FontAwesomeIcon icon={faEnvelope} /> Company Email
            </label>
            <input
              type="email"
              id="companyEmail"
              name="companyEmail"
              value={formData.companyEmail}
              onChange={handleChange}
              required
              placeholder="Enter your company email"
            />
          </div>

          {/* Phone Number */}
          <div className="register-form-group">
            <label htmlFor="phoneNumber">
              <FontAwesomeIcon icon={faPhone} /> Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              placeholder="Enter your phone number"
            />
          </div>

          {/* Company Type */}
          <div className="register-form-group">
            <label htmlFor="companyType">
              <FontAwesomeIcon icon={faTruck} /> Company Type
            </label>
            <select
              id="companyType"
              name="companyType"
              value={formData.companyType}
              onChange={handleChange}
              required
            >
              <option value="">Select Company Type</option>
              <option value="Shipping">Shipping</option>
              <option value="Delivery">Delivery</option>
            </select>
          </div>

          {/* DOT or MC Number */}
          <div className="register-form-group">
            <label htmlFor="dotOrMcNumber">
              <FontAwesomeIcon icon={faIdCard} /> DOT or MC Number
            </label>
            <input
              type="text"
              id="dotOrMcNumber"
              name="dotOrMcNumber"
              value={formData.dotOrMcNumber}
              onChange={handleChange}
              required
              placeholder="Enter your DOT or MC number"
            />
          </div>

          {/* Registration Type */}
          <div className="register-form-group">
            <label htmlFor="registrationType">Registration Type</label>
            <select
              id="registrationType"
              name="registrationType"
              value={formData.registrationType}
              onChange={handleChange}
              required
            >
              <option value="">Select Registration Type</option>
              <option value="DOT">DOT</option>
              <option value="MC">MC</option>
            </select>
          </div>

          {/* Submit Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={loading}
            className="submit-btn"
          >
            {loading ? "Registering…" : "Register"}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default RegistrationForm;

import React from "react";
import { motion } from "framer-motion";
import ShipmentComponent from "../components/ShipmentForm/ShipmentComponent";
import "./Loadboard.css";

const LoadBoard = () => {


  return (
    <motion.div
      className="loadboard"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Load Board</h2>
      <ShipmentComponent/>
    </motion.div>
  );
};

export default LoadBoard;

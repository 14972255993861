const TOKEN_KEY = "app_token";
const COMPANY_KEY = "app_company";
const REFRESH_TOKEN_KEY = "app_refreshToken";

const storage = {
  set(key, value) {
    try {
      const serializedValue =
        typeof value === "object" ? JSON.stringify(value) : value;
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error setting ${key} in localStorage:`, error);
    }
  },
  get(key) {
    try {
      const value = localStorage.getItem(key);
      return value ? (key === COMPANY_KEY ? JSON.parse(value) : value) : null;
    } catch (error) {
      console.error(`Error getting ${key} from localStorage:`, error);
      return null;
    }
  },
  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from localStorage:`, error);
    }
  },
};

export const setStoredToken = (token) => storage.set(TOKEN_KEY, token);
export const getStoredToken = () => storage.get(TOKEN_KEY);
export const removeStoredToken = () => storage.remove(TOKEN_KEY);

export const setStoredCompany = (company) => storage.set(COMPANY_KEY, company);
export const getStoredCompany = () => storage.get(COMPANY_KEY);
export const removeStoredCompany = () => storage.remove(COMPANY_KEY);

export const setStoredRefreshToken = (refreshToken) =>
  storage.set(REFRESH_TOKEN_KEY, refreshToken);
export const getStoredRefreshToken = () => storage.get(REFRESH_TOKEN_KEY);
export const removeStoredRefreshToken = () => storage.remove(REFRESH_TOKEN_KEY);

export const clearAllStoredAuthData = () => {
  removeStoredToken();
  removeStoredRefreshToken();
  removeStoredCompany();
};

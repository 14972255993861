import React, { useCallback } from "react";
import styled from "styled-components";
import ShipmentTable from "./ShipmentTable";

const SectionContainer = styled.div`
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
`;

const SectionHeader = styled.h2`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 15px;
`;

const EmptyMessage = styled.p`
  color: #666;
  font-size: 0.9rem;
  text-align: center;
`;

const DashboardContent = ({ shipments = {}, onView, onComplete }) => {
  /**
   * Ensures the shipment quantity is properly normalized as a number
   * @param {Array} shipmentList - List of shipments to normalize
   * @returns {Array} Normalized shipment list
   */
  const normalizeShipments = useCallback((shipmentList) => {
    return shipmentList.map((shipment) => ({
      ...shipment,
      shipmentQuantity: Number(shipment.shipmentQuantity),
    }));
  }, []);

  /**
   * Renders either a shipment table or a message if the list is empty
   * @param {Array} shipmentList - List of shipments to render
   * @param {string} message - Message to display if the list is empty
   * @returns {JSX.Element} Rendered table or empty message
   */
  const renderTableOrMessage = useCallback(
    (shipmentList, message) => {
      if (!shipmentList || shipmentList.length === 0) {
        return <EmptyMessage>{message}</EmptyMessage>;
      }
      return (
        <ShipmentTable
          shipments={normalizeShipments(shipmentList)}
          onView={onView}
          onDelete={onComplete}
        />
      );
    },
    [normalizeShipments, onView, onComplete]
  );

  return (
    <>
      <SectionContainer>
        <SectionHeader>Available Shipments</SectionHeader>
        {renderTableOrMessage(
          shipments.available || [],
          "No available shipments found."
        )}
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>In Transit Shipments</SectionHeader>
        {renderTableOrMessage(
          shipments.inTransit || [],
          "No in-transit shipments found."
        )}
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>Completed Shipments</SectionHeader>
        {renderTableOrMessage(
          shipments.completed || [],
          "No completed shipments found."
        )}
      </SectionContainer>
    </>
  );
};

export default DashboardContent;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faFilter } from "@fortawesome/free-solid-svg-icons";

const AssignDriverContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
`;

const Header = styled.h3`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
`;

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const FilterSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
`;

const ScrollableDiv = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #fff;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const StatusSpan = styled.span`
  font-weight: bold;
  color: ${({ status }) =>
    status === "In Use"
      ? "green"
      : status === "Under Maintenance"
      ? "orange"
      : status === "Available"
      ? "blue"
      : "gray"};
`;

const AssignDriverToTruck = ({ trucks = [] }) => {
  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const [filter, setFilter] = useState("All");

  // Log the trucks data to debug
  useEffect(() => {
    console.log("[AssignDriverToTruck] Trucks data:", trucks);
  }, [trucks]);

  useEffect(() => {
    setFilteredTrucks(
      filter === "All"
        ? trucks
        : trucks.filter((truck) => truck.status === filter)
    );
  }, [filter, trucks]);

  return (
    <AssignDriverContainer>
      <Header>
        <FontAwesomeIcon icon={faTruck} /> Truck Assignment Status
      </Header>
      <FilterBar>
        <label htmlFor="truck-filter">
          <FontAwesomeIcon icon={faFilter} /> Filter Trucks:
        </label>
        <FilterSelect
          id="truck-filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Available">Available</option>
          <option value="In Use">In Use</option>
          <option value="Under Maintenance">Under Maintenance</option>
        </FilterSelect>
      </FilterBar>
      <ScrollableDiv>
        <Table>
          <thead>
            <tr>
              <Th>Truck</Th>
              <Th>Driver</Th>
              <Th>Status</Th>
            </tr>
          </thead>
          <tbody>
            {filteredTrucks.map((truck) => (
              <tr key={truck.vin || truck.truckNumber}>
                <Td>
                  {truck.make} {truck.model} ({truck.licensePlate})
                </Td>
                <Td>
                  {truck.assignedDriver
                    ? `${truck.assignedDriver.fullName} (${truck.assignedDriver.email})`
                    : "Unassigned"}
                </Td>
                <Td>
                  <StatusSpan status={truck.status}>{truck.status}</StatusSpan>
                </Td>
              </tr>
            ))}
            {filteredTrucks.length === 0 && (
              <tr>
                <Td colSpan="3" style={{ textAlign: "center", color: "gray" }}>
                  No trucks found.
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollableDiv>
    </AssignDriverContainer>
  );
};

export default AssignDriverToTruck;

import React from "react";
import "./AvatarWrapper.styles.css";

const AvatarWrapper = ({ src, companyName }) => {
  const fallbackInitials = companyName
    .split(" ")
    .map((word) => word[0])
    .join("");

  return (
    <div className="avatar-wrapper">
      {src ? (
        <img src={src} alt={companyName} className="avatar-image" />
      ) : (
        <div className="avatar-initials">{fallbackInitials}</div>
      )}
    </div>
  );
};

export default AvatarWrapper;

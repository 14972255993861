import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import DashboardContent from "./DashboardContent";
import AssignDriverToTruck from "./AssignDriverToTruck";
import ShipmentDetailsDialog from "./ShipmentDetailsDialog";
import { useAuth } from "./AuthContext/AuthContext";

const DashboardContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background: #f7f7f7;
  min-height: 100vh;
`;

const Dashboard = ({ resources }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company, handleCompleteShipment, fetchAllShipments } = useAuth();
  const companyId = resources?.companyId || company?.id;

  const [categorizedShipments, setCategorizedShipments] = useState({
    available: [],
    inTransit: [],
    completed: [],
  });
  const [selectedShipment, setSelectedShipment] = useState(null);

  const fetchAndCategorizeShipments = useCallback(async () => {
    if (!companyId) {
      console.warn("[Dashboard] Missing companyId. Unable to fetch shipments.");
      enqueueSnackbar("Missing company information. Cannot fetch shipments.", {
        variant: "error",
      });
      return;
    }

    try {
      console.log(`[Dashboard] Fetching shipments for companyId: ${companyId}`);
      const shipments = await fetchAllShipments();

      if (!Array.isArray(shipments)) {
        console.warn("[Dashboard] Invalid shipment data received.");
        setCategorizedShipments({
          available: [],
          inTransit: [],
          completed: [],
        });
        enqueueSnackbar("No shipments available for this company.", {
          variant: "info",
        });
        return;
      }

      const filteredShipments = shipments.filter(
        (shipment) => shipment?.deliveryCompanyId === companyId
      );

      if (filteredShipments.length === 0) {
        console.info("[Dashboard] No shipments available for this company.");
        setCategorizedShipments({
          available: [],
          inTransit: [],
          completed: [],
        });
        enqueueSnackbar("No shipments available for this company.", {
          variant: "info",
        });
        return;
      }

      setCategorizedShipments({
        available: filteredShipments.filter((s) => s.status === "Available"),
        inTransit: filteredShipments.filter((s) => s.status === "In Transit"),
        completed: filteredShipments.filter((s) => s.status === "Completed"),
      });

      console.log("[Dashboard] Shipments categorized successfully.");
    } catch (error) {
      console.error("[Dashboard] Error fetching shipments:", error.message);
      enqueueSnackbar("Failed to load shipments. Please try again.", {
        variant: "error",
      });
    }
  }, [companyId, fetchAllShipments, enqueueSnackbar]);

  useEffect(() => {
    fetchAndCategorizeShipments();
  }, [fetchAndCategorizeShipments]);

  const handleComplete = useCallback(
    async (shipment) => {
      if (!shipment || !shipment._id) {
        console.warn("[Dashboard] Invalid shipment data:", shipment);
        enqueueSnackbar("Invalid shipment data. Cannot complete.", {
          variant: "warning",
        });
        return;
      }

      try {
        console.log("[Dashboard] Completing shipment:", shipment);

        const response = await handleCompleteShipment(shipment);

        if (response?.data?.success) {
          console.log("[Dashboard] Shipment completed successfully.");
          enqueueSnackbar("Shipment completed successfully.", {
            variant: "success",
          });
          fetchAndCategorizeShipments();
        } else {
          console.warn("[Dashboard] Failed to complete shipment.");
          enqueueSnackbar("Failed to complete shipment. Please try again.", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error(
          "[Dashboard] Error during shipment completion:",
          error.message
        );
        enqueueSnackbar("Error completing shipment. Please try again.", {
          variant: "error",
        });
      }
    },
    [handleCompleteShipment, fetchAndCategorizeShipments, enqueueSnackbar]
  );

  return (
    <DashboardContainer>
      {resources?.allTrucks && resources?.allDrivers && (
        <AssignDriverToTruck
          trucks={resources.allTrucks}
          drivers={resources.allDrivers}
        />
      )}

      <DashboardContent
        shipments={categorizedShipments}
        onView={setSelectedShipment}
        onComplete={handleComplete}
      />

      {selectedShipment && (
        <ShipmentDetailsDialog
          open={!!selectedShipment}
          shipment={selectedShipment}
          onClose={() => setSelectedShipment(null)}
          onComplete={handleComplete}
        />
      )}
    </DashboardContainer>
  );
};

export default Dashboard;

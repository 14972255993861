import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import ShipmentDetailsDialog from "./ShipmentDetailsDialog";
import ShipmentTable from "./ShipmentTable";
import { useAuth } from "./AuthContext/AuthContext";
import axiosInstance from "./axiosInstance";

const CustomContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: "20px",
  borderRadius: "16px",
  boxShadow: theme.shadows[3],
}));

const CustomTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  marginBottom: "20px",
  textAlign: "center",
}));

const ViewShipments = ({
  localResources = { companyId: "", allTrucks: [], allDrivers: [] },
}) => {
  const { fetchAllShipments } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [availableShipments, setAvailableShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchAvailableShipments = useCallback(async () => {
    console.log("[ViewShipments] Fetching available shipments...");

    if (!localResources.companyId) {
      enqueueSnackbar("Company ID is missing. Cannot fetch shipments.", {
        variant: "error",
      });
      return;
    }

    setLoading(true);

    try {
      const allShipments = await fetchAllShipments();

      if (!allShipments || allShipments.length === 0) {
        enqueueSnackbar("No available shipments found.", { variant: "info" });
        setAvailableShipments([]);
        return;
      }

      const available = allShipments.filter(
        (shipment) => shipment.status === "Available"
      );

      setAvailableShipments(available);
    } catch (error) {
      console.error(
        "[ViewShipments] Error fetching available shipments:",
        error
      );
      enqueueSnackbar("Failed to load available shipments. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [fetchAllShipments, localResources.companyId, enqueueSnackbar]);

  useEffect(() => {
    fetchAvailableShipments();
  }, [fetchAvailableShipments]);

const handlePickupShipment = useCallback(
  async (truckId, driverId) => {
    if (!selectedShipment || !truckId || !driverId) {
      console.warn("[handlePickupShipment] Missing required fields:", {
        selectedShipment,
        truckId,
        driverId,
      });
      enqueueSnackbar("Please select all required fields.", {
        variant: "warning",
      });
      return;
    }

    const shipmentId = selectedShipment?._id;
    const deliveryCompanyId = localResources?.companyId;

    if (!shipmentId || !deliveryCompanyId) {
      console.error(
        "[handlePickupShipment] Invalid shipmentId or deliveryCompanyId:",
        {
          shipmentId,
          deliveryCompanyId,
        }
      );
      enqueueSnackbar(
        "Invalid shipment or company details. Please try again.",
        {
          variant: "error",
        }
      );
      return;
    }

    setActionLoading(true);
    try {
      const payload = {
        shipmentId,
        assignedTruckId: truckId,
        assignedDriverId: driverId,
        deliveryCompanyId,
        shipment: {
          shipmentNumber: selectedShipment.shipmentNumber,
          description: selectedShipment.description,
          weight: selectedShipment.weight,
          origin: selectedShipment.origin,
          destination: selectedShipment.destination,
        },
      };

      console.log(
        "[handlePickupShipment] Payload being sent to server:",
        payload
      );

      const response = await axiosInstance.patch(
        `/company/${localResources.companyId}/pickup`,
        payload
      );

      console.log(
        "[handlePickupShipment] Response from server:",
        response.data
      );

      if (response.data?.success) {
        console.info(
          "[handlePickupShipment] Pickup successful:",
          response.data
        );
        enqueueSnackbar("Pickup successful!", { variant: "success" });
        fetchAvailableShipments();
      } else {
        console.error("[handlePickupShipment] Server error:", response.data);
        throw new Error(response.data?.error || "Failed to pick up shipment.");
      }
    } catch (error) {
      console.error("[handlePickupShipment] Pickup error:", {
        message: error.message,
        stack: error.stack,
        selectedShipment,
        truckId,
        driverId,
        localResources,
      });
      enqueueSnackbar("Error during pickup. Please try again.", {
        variant: "error",
      });
    } finally {
      setActionLoading(false);
    }
  },
  [
    selectedShipment,
    localResources.companyId,
    fetchAvailableShipments,
    enqueueSnackbar,
  ]
);


  const handleCompleteShipment = useCallback(async () => {
    if (!selectedShipment) {
      enqueueSnackbar("Invalid shipment selected.", { variant: "warning" });
      return;
    }

    setActionLoading(true);
    try {
      const response = await axiosInstance.patch(
        `/company/${localResources.companyId}/complete`,
        { shipmentId: selectedShipment._id }
      );

      if (response.data?.success) {
        enqueueSnackbar("Shipment completed successfully!", {
          variant: "success",
        });
        fetchAvailableShipments();
        setSelectedShipment(null);
      } else {
        throw new Error(response.data?.error || "Failed to complete shipment.");
      }
    } catch (error) {
      console.error("Completion error:", error);
      enqueueSnackbar("Error completing shipment. Please try again.", {
        variant: "error",
      });
    } finally {
      setActionLoading(false);
    }
  }, [
    selectedShipment,
    localResources.companyId,
    fetchAvailableShipments,
    enqueueSnackbar,
  ]);

  const handleBidOnShipment = useCallback(
    async (bidAmount) => {
      if (!selectedShipment || !bidAmount || bidAmount <= 0) {
        enqueueSnackbar("Invalid bid amount or shipment selection.", {
          variant: "warning",
        });
        return;
      }

      try {
        const payload = {
          shipmentId: selectedShipment._id,
          companyId: localResources.companyId,
          bidAmount,
        };

        const response = await axiosInstance.post(
          `/company/${localResources.companyId}/bid`,
          payload
        );

        if (response.data?.success) {
          enqueueSnackbar("Bid placed successfully!", { variant: "success" });
          fetchAvailableShipments();
        } else {
          throw new Error(response.data?.error || "Failed to place bid.");
        }
      } catch (error) {
        console.error("Bid error:", error);
        enqueueSnackbar("Error placing bid. Please try again.", {
          variant: "error",
        });
      }
    },
    [
      selectedShipment,
      localResources.companyId,
      fetchAvailableShipments,
      enqueueSnackbar,
    ]
  );

  const sanitizedShipments = availableShipments.map((shipment) => ({
    ...shipment,
    shipmentQuantity: Number(shipment.shipmentQuantity),
  }));

  return (
    <CustomContainer>
      <CustomTitle>Your Shipments</CustomTitle>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : sanitizedShipments.length > 0 ? (
        <ShipmentTable
          shipments={sanitizedShipments}
          onView={setSelectedShipment}
        />
      ) : (
        <Typography align="center">No shipments available.</Typography>
      )}

      {selectedShipment && (
        <ShipmentDetailsDialog
          open={!!selectedShipment}
          shipment={selectedShipment}
          onPickup={handlePickupShipment}
          onComplete={handleCompleteShipment}
          availableTrucks={localResources?.allTrucks || []}
          availableDrivers={localResources?.allDrivers || []}
          loadingAction={actionLoading}
          onSubmitOffer={handleBidOnShipment}
          onClose={() => setSelectedShipment(null)}
        />
      )}
    </CustomContainer>
  );
};

ViewShipments.propTypes = {
  localResources: PropTypes.shape({
    allTrucks: PropTypes.array,
    allDrivers: PropTypes.array,
    companyId: PropTypes.string,
  }),
};

ViewShipments.defaultProps = {
  localResources: {
    companyId: "",
    allTrucks: [],
    allDrivers: [],
  },
};

export default ViewShipments;

export default async function fetchAddressDetails(address) {
  if (!address) return null;

  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyDmgP3piUoqTWjXGuocM7W7K-x6pAENI4I`
    );

    // Log the response status and any available headers for debugging
    console.log("Response Status:", response.status);
    if (!response.ok) {
      console.error("Error in response:", response.statusText);
      return null;
    }

    const data = await response.json();
    console.log("Fetched Data:", data);

    // Check if there are any results and log if there aren’t any
    if (data.results && data.results.length > 0) {
      return data.results[0];
    } else {
      console.warn("No address details found for:", address);
      return null;
    }
  } catch (error) {
    console.error("Error fetching address details:", error);
    return null;
  }
}

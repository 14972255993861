import React, { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useAuth } from "./AuthContext/AuthContext";
import {
  ShipmentFormContainer,
  FormWrapper,
  Title,
  StyledButton,
} from "./ShipmentStyles";
import axiosInstance from "./axiosInstance";
import fetchAddressDetails from "./fetchAddressDetails";
import calculateTotalMiles from "./calculateTotalMiles";
import ShipmentFormFields from "./ShipmentFormFields";

const initialState = {
  weight: "",
  description: "",
  priceRange: "",
  pickupTime: "",
  dropOffTime: "",
  totalMiles: 0,
  totalCost: 0,
  maxNegotiationPrice: "",
  origin: { address: "", street: "", city: "", state: "", zipCode: "" },
  destination: { address: "", street: "", city: "", state: "", zipCode: "" },
  isRoundTrip: false,
  departureDate: "",
  costPerMile: 3,
  truckType: "",
  truckSize: "",
  shipmentNumber: "",
  shipmentQuantity: "",
};

const parseAddressComponents = (components) => {
  const addressData = { street: "", city: "", state: "", zipCode: "" };

  components.forEach((component) => {
    const { types, long_name, short_name } = component;

    if (types.includes("street_number")) {
      addressData.street = `${long_name} `;
    }
    if (types.includes("route")) {
      addressData.street += long_name;
    }
    if (types.includes("locality") || types.includes("sublocality")) {
      addressData.city = long_name;
    }
    if (types.includes("administrative_area_level_1")) {
      addressData.state = short_name;
    }
    if (types.includes("postal_code")) {
      addressData.zipCode = long_name;
    }
  });

  console.log("[CLIENT] Parsed Address Data:", addressData);
  return addressData;
};

const PostShipment = ({ onShipmentSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useAuth();
  const [formState, setFormState] = useState(initialState);
  const [lastFetchedAddresses, setLastFetchedAddresses] = useState({
    origin: "",
    destination: "",
  });

  const handleFetchDistanceAndCost = useCallback(async () => {
    const { origin, destination, isRoundTrip } = formState;

    if (
      origin.address === lastFetchedAddresses.origin &&
      destination.address === lastFetchedAddresses.destination
    ) {
      console.log("[CLIENT] Skipping fetch: Addresses have not changed.");
      return;
    }

    if (origin.address && destination.address) {
      try {
        console.log("[CLIENT] Fetching address details...");
        const [originDetails, destinationDetails] = await Promise.all([
          fetchAddressDetails(origin.address),
          fetchAddressDetails(destination.address),
        ]);

        if (originDetails && destinationDetails) {
          const parsedOrigin = parseAddressComponents(
            originDetails.address_components
          );
          const parsedDestination = parseAddressComponents(
            destinationDetails.address_components
          );

          console.log("[CLIENT] Parsed Origin:", parsedOrigin);
          console.log("[CLIENT] Parsed Destination:", parsedDestination);

          const totalMiles = calculateTotalMiles(
            originDetails,
            destinationDetails,
            isRoundTrip
          );

          console.log("[CLIENT] Calculated Total Miles:", totalMiles);

          setFormState((prev) => ({
            ...prev,
            origin: { ...prev.origin, ...parsedOrigin },
            destination: { ...prev.destination, ...parsedDestination },
            totalMiles,
            totalCost: (totalMiles * prev.costPerMile).toFixed(2),
          }));

          setLastFetchedAddresses({
            origin: origin.address,
            destination: destination.address,
          });
        } else {
          enqueueSnackbar("Failed to fetch address details.", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("[CLIENT] Error fetching address details:", error);
        enqueueSnackbar("Failed to calculate distance and cost.", {
          variant: "error",
        });
      }
    }
  }, [formState, lastFetchedAddresses, enqueueSnackbar]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handleFetchDistanceAndCost();
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [
    formState.origin.address,
    formState.destination.address,
    handleFetchDistanceAndCost,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("[CLIENT] Form submission initiated:", formState);

    const { origin, destination } = formState;

    if (
      !origin.street ||
      !origin.city ||
      !origin.state ||
      !origin.zipCode ||
      !destination.street ||
      !destination.city ||
      !destination.state ||
      !destination.zipCode
    ) {
      console.warn("[CLIENT] Address validation failed:", {
        origin,
        destination,
      });
      enqueueSnackbar(
        "Complete address details are required for origin and destination.",
        { variant: "error" }
      );
      return;
    }

    const shipmentData = {
      ...formState,
      weight: parseFloat(formState.weight),
      companyId: company?.id,
    };

   try {
     console.log("[CLIENT] Sending shipment data to server:", shipmentData);
     const response = await axiosInstance.post("/shipment/new", shipmentData);
     console.log("[CLIENT] Shipment posted successfully:", response.data);
     enqueueSnackbar("Shipment posted successfully", { variant: "success" });
     setFormState(initialState);
     if (onShipmentSubmit) onShipmentSubmit(response.data);
   } catch (error) {
     console.error("[CLIENT] Error posting shipment:", error);
     const errorMessage =
       error.response?.data?.message ||
       "Failed to post shipment. Please try again.";
     enqueueSnackbar(errorMessage, { variant: "error" });
   }

  };

  return (
    <ShipmentFormContainer>
      <FormWrapper>
        <Title variant="h5">Post Shipment</Title>
        <form onSubmit={handleSubmit}>
          <ShipmentFormFields
            formState={formState}
            setFormState={setFormState}
          />
          <StyledButton type="submit">Post Shipment</StyledButton>
        </form>
      </FormWrapper>
    </ShipmentFormContainer>
  );
};

export default PostShipment;

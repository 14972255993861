import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrash,
  faArrowRight,
  faArrowsAltH,
  faTruck,
  faRulerHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

// Transient props are prefixed with `$` to prevent them from being passed to the DOM
const ActionButton = styled(Button)`
  margin-right: 8px;
  padding: 10px 15px;
`;

const ShipmentTableContainer = styled(TableContainer)`
  margin-top: 20px;
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;

const MobileShipmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileShipmentCard = styled(Paper)`
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DirectionIcon = styled(FontAwesomeIcon)`
  color: #007bff;
  margin: 0px 8px;
`;

const ShipmentTable = React.memo(({ shipments, onView }) => {
  if (!shipments || shipments.length === 0) {
    return (
      <Typography variant="h6" align="center">
        No shipments available.
      </Typography>
    );
  }

  return (
    <>
      {/* Desktop View */}
      <ShipmentTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shipper</TableCell>
              <TableCell>Route</TableCell>
              <TableCell>Truck Type & Size</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments.map((shipment) => (
              <TableRow key={shipment._id}>
                <TableCell>
                  {shipment.shipper?.companyName || "Unknown Shipper"}
                </TableCell>
                <TableCell>
                  {shipment.origin?.city || "Unknown City"},{" "}
                  {shipment.origin?.state || "Unknown State"}
                  {shipment.isRoundTrip ? (
                    <DirectionIcon icon={faArrowsAltH} title="Round Trip" />
                  ) : (
                    <DirectionIcon icon={faArrowRight} title="One Way" />
                  )}
                  {shipment.destination?.city || "Unknown City"},{" "}
                  {shipment.destination?.state || "Unknown State"}
                </TableCell>
                <TableCell>
                  <FontAwesomeIcon icon={faTruck} style={{ marginRight: 5 }} />
                  {shipment.truckType || "N/A"}
                  <FontAwesomeIcon
                    icon={faRulerHorizontal}
                    style={{ margin: "0 5px" }}
                  />
                  {shipment.truckSize || "N/A"} ft
                </TableCell>
                <TableCell>${shipment.totalCost || "N/A"}</TableCell>
                <TableCell>{shipment.shipmentQuantity || "N/A"}</TableCell>
                <TableCell>{shipment.status || "Unknown"}</TableCell>
                <TableCell>
                  <ActionButton
                    onClick={() => onView(shipment)}
                    aria-label="View Shipment"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ActionButton>
                  <ActionButton
                    onClick={() => console.log(`Delete: ${shipment._id}`)}
                    aria-label="Delete Shipment"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ShipmentTableContainer>

      {/* Mobile View */}
      <MobileShipmentList>
        {shipments.map((shipment) => (
          <MobileShipmentCard key={shipment._id}>
            <Typography variant="subtitle1" fontWeight="bold">
              {shipment.shipper?.companyName || "Unknown Shipper"}
            </Typography>
            <Typography variant="body2">
              <strong>Route:</strong> {shipment.origin?.city || "Unknown City"},{" "}
              {shipment.origin?.state || "Unknown State"}
              {shipment.isRoundTrip ? (
                <DirectionIcon icon={faArrowsAltH} title="Round Trip" />
              ) : (
                <DirectionIcon icon={faArrowRight} title="One Way" />
              )}
              {shipment.destination?.city || "Unknown City"},{" "}
              {shipment.destination?.state || "Unknown State"}
            </Typography>
            <Typography variant="body2">
              <strong>Truck:</strong> <FontAwesomeIcon icon={faTruck} />{" "}
              {shipment.truckType || "N/A"}, {shipment.truckSize || "N/A"} ft
            </Typography>
            <Typography variant="body2">
              <strong>Total Cost:</strong> ${shipment.totalCost || "N/A"}
            </Typography>
            <Typography variant="body2">
              <strong>Status:</strong> {shipment.status || "Unknown"}
            </Typography>
            <div style={{ display: "flex", gap: 8 }}>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={() => onView(shipment)}
                aria-label="View Shipment"
              >
                <FontAwesomeIcon icon={faEye} />
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="secondary"
                onClick={() => console.log(`Delete: ${shipment._id}`)}
                aria-label="Delete Shipment"
              >
                <FontAwesomeIcon icon={faTrash} />
              </ActionButton>
            </div>
          </MobileShipmentCard>
        ))}
      </MobileShipmentList>
    </>
  );
});

ShipmentTable.propTypes = {
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      shipper: PropTypes.object,
      origin: PropTypes.object,
      destination: PropTypes.object,
      truckType: PropTypes.string,
      truckSize: PropTypes.number,
      totalCost: PropTypes.number,
      shipmentQuantity: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  onView: PropTypes.func.isRequired,
};

export default ShipmentTable;

// import React, { useState, useEffect } from "react";
// import { Typography, Divider, Avatar, Modal } from "@mui/material";
// import styled from "styled-components";
// import {
//   faTruck,
//   faBuilding,
//   faEnvelope,
//   faPhone,
//   faDollarSign,
//   faWeightHanging,
//   faInfoCircle,
//   faRoute,
//   faSyncAlt,
//   faMapMarkerAlt,
//   faCalendarAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useAuth } from "./AuthContext/AuthContext";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 20px;
//   background-color: #ffffff;
//   border-radius: 16px;
//   box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
//   @media (max-width: 768px) {
//     padding: 15px;
//   }
// `;

// const DetailRow = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 12px;
// `;

// const InfoTitle = styled(Typography)`
//   font-weight: bold;
//   font-size: 1.2rem;
//   color: #3f51b5;
//   @media (max-width: 768px) {
//     font-size: 1rem;
//   }
// `;

// const DetailText = styled(Typography)`
//   font-size: 1rem;
//   color: #555;
//   @media (max-width: 768px) {
//     font-size: 0.9rem;
//   }
// `;

// const CompanyInfoBox = styled.div`
//   display: flex;
//   align-items: center;
//   background: #f9f9f9;
//   border-radius: 16px;
//   padding: 12px;
//   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
//   cursor: pointer;
//   transition: transform 0.2s;
//   &:hover {
//     transform: scale(1.05);
//     background: #f1f1f1;
//   }
// `;

// const ModalContent = styled.div`
//   background-color: #ffffff;
//   border-radius: 16px;
//   padding: 20px;
//   width: 90%;
//   max-width: 400px;
//   box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.3);
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// `;

// const CloseButton = styled.button`
//   background-color: #f44336;
//   color: white;
//   border: none;
//   padding: 10px;
//   border-radius: 8px;
//   font-weight: bold;
//   cursor: pointer;
//   &:hover {
//     background-color: #d32f2f;
//   }
// `;

// const ShipmentDetailsSection = ({ shipment }) => {
//   const [selectedCompany, setSelectedCompany] = useState(null);
//   const [shipperInfo, setShipperInfo] = useState(null);
//   const [deliveryCompanyInfo, setDeliveryCompanyInfo] = useState(null);
//   const { fetchResources } = useAuth();
// console.log(shipment)
//   useEffect(() => {
//     if (!shipment) return;


//     setShipperInfo((prev) =>
//       prev?._id === shipment.shipper?._id ? prev : shipment.shipper || null
//     );

//     setDeliveryCompanyInfo((prev) =>
//       prev?._id === shipment.deliveryCompany?._id
//         ? prev
//         : shipment.deliveryCompany || null
//     );
//   }, [shipment]);


//   const renderCompanyInfo = (company) => (
//     <>
//       <Avatar
//         src={company.logoUrl || "/default-logo.png"}
//         sx={{ width: 80, height: 80, margin: "0 auto" }}
//       />
//       <Typography variant="h6" sx={{ fontWeight: 700, textAlign: "center" }}>
//         {company.companyName || "Unknown"}
//       </Typography>
//       <DetailText>
//         <FontAwesomeIcon icon={faBuilding} style={{ marginRight: 8 }} />
//         Address: {company.companyAddress || "Unknown"}
//       </DetailText>
//       <DetailText>
//         <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
//         Email: {company.companyEmail || "Not Provided"}
//       </DetailText>
//       <DetailText>
//         <FontAwesomeIcon icon={faPhone} style={{ marginRight: 8 }} />
//         Phone: {company.phoneNumber || "Not Provided"}
//       </DetailText>
//     </>
//   );

//   return (
//     <Container>
//       <Divider />
//       <Typography variant="h6" gutterBottom>
//         Shipper Details:
//       </Typography>
//       {shipperInfo ? (
//         <CompanyInfoBox onClick={() => setSelectedCompany(shipperInfo)}>
//           <Avatar src={shipperInfo.logoUrl || "/default-logo.png"} />
//           <Typography>{shipperInfo.companyName}</Typography>
//         </CompanyInfoBox>
//       ) : (
//         <Typography>No shipper details available.</Typography>
//       )}

//       <Divider />
//       <Typography variant="h6" gutterBottom>
//         Delivery Company Details:
//       </Typography>
//       {deliveryCompanyInfo ? (
//         <CompanyInfoBox onClick={() => setSelectedCompany(deliveryCompanyInfo)}>
//           <Avatar src={deliveryCompanyInfo.logoUrl || "/default-logo.png"} />
//           <Typography>{deliveryCompanyInfo.companyName}</Typography>
//         </CompanyInfoBox>
//       ) : (
//         <Typography>No delivery company details available.</Typography>
//       )}

//       <DetailRow>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faRoute} style={{ marginRight: 8 }} />
//           Truck Number:{" "}
//           <DetailText component="span">
//             {shipment.assignedTruck?.truckNumber || "Not Assigned"}
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: 8 }} />
//           Driver:{" "}
//           <DetailText component="span">
//             {shipment.assignedDriver?.fullName || "Not Assigned"}
//           </DetailText>
//         </InfoTitle>
//       </DetailRow>

//       <DetailRow>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faDollarSign} style={{ marginRight: 8 }} />
//           Total Cost:{" "}
//           <DetailText component="span">
//             ${shipment.totalCost?.toFixed(2) || "N/A"}
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 8 }} />
//           Description:{" "}
//           <DetailText component="span">
//             {shipment.description || "N/A"}
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faWeightHanging} style={{ marginRight: 8 }} />
//           Weight:{" "}
//           <DetailText component="span">
//             {shipment.weight || "N/A"} lbs
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
//           Origin:{" "}
//           <DetailText component="span">
//             {shipment.origin?.street || "N/A"}, {shipment.origin?.city || "N/A"}
//             , {shipment.origin?.state || "N/A"} -{" "}
//             {shipment.origin?.zipCode || "N/A"}
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
//           Destination:{" "}
//           <DetailText component="span">
//             {shipment.destination?.street || "N/A"},{" "}
//             {shipment.destination?.city || "N/A"},{" "}
//             {shipment.destination?.state || "N/A"} -{" "}
//             {shipment.destination?.zipCode || "N/A"}
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />
//           Pickup Date:{" "}
//           <DetailText component="span">
//             {shipment.pickupTime
//               ? new Date(shipment.pickupTime).toLocaleDateString()
//               : "N/A"}
//           </DetailText>
//         </InfoTitle>
//         <InfoTitle>
//           <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />
//           Drop-off Date:{" "}
//           <DetailText component="span">
//             {shipment.dropOffTime
//               ? new Date(shipment.dropOffTime).toLocaleDateString()
//               : "N/A"}
//           </DetailText>
//         </InfoTitle>
//       </DetailRow>

//       <Modal
//         open={!!selectedCompany}
//         onClose={() => setSelectedCompany(null)}
//         aria-labelledby="company-modal"
//       >
//         <ModalContent>
//           {selectedCompany ? (
//             <>
//               {renderCompanyInfo(selectedCompany)}
//               <CloseButton onClick={() => setSelectedCompany(null)}>
//                 Close
//               </CloseButton>
//             </>
//           ) : (
//             <Typography>Loading company details...</Typography>
//           )}
//         </ModalContent>
//       </Modal>
//     </Container>
//   );
// };

// export default ShipmentDetailsSection;





import React, { useState } from "react";
import { Typography, Divider, Avatar, Modal, Box } from "@mui/material";
import styled from "styled-components";
import {
  faTruck,
  faBuilding,
  faEnvelope,
  faPhone,
  faDollarSign,
  faWeightHanging,
  faInfoCircle,
  faRoute,
  faSyncAlt,
  faCalendarAlt,
  faClipboardCheck,
  faListAlt,
  faFileInvoiceDollar,
  faBalanceScale,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "./AuthContext/AuthContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InfoTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1rem;
  color: #3f51b5;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const DetailText = styled(Typography)`
  font-size: 0.9rem;
  color: #555;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const CompanyInfoBox = styled.div`
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 16px;
  padding: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
    background: #f1f1f1;
  }
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CloseButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #d32f2f;
  }
`;

const ShipmentDetailsSection = ({ shipment }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { company } = useAuth();

  const renderCompanyInfo = (company) => (
    <>
      <Avatar
        src={company.logoUrl || "/default-logo.png"}
        sx={{ width: 80, height: 80, margin: "0 auto" }}
      />
      <Typography variant="h6" sx={{ fontWeight: 700, textAlign: "center" }}>
        {company.companyName || "Unknown"}
      </Typography>
      <DetailText>
        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: 8 }} />
        Address: {company.companyAddress || "Unknown"}
      </DetailText>
      <DetailText>
        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
        Email: {company.companyEmail || "Not Provided"}
      </DetailText>
      <DetailText>
        <FontAwesomeIcon icon={faPhone} style={{ marginRight: 8 }} />
        Phone: {company.phoneNumber || "Not Provided"}
      </DetailText>
      <DetailText>
        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: 8 }} />
        DOT: {company.dotOrMcNumber || "Not Provided"}
      </DetailText>
      <DetailText>
        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: 8 }} />
        Status: {company.operatingStatus || "Not Provided"}
      </DetailText>
    </>
  );

  const renderDetails = (label, value, icon) => (
    <InfoTitle>
      <FontAwesomeIcon icon={icon} style={{ marginRight: 8 }} />
      {label}: <DetailText component="span">{value || "N/A"}</DetailText>
    </InfoTitle>
  );

  return (
    <Container>
      <Divider />
      <Typography variant="h6" gutterBottom>
        Shipper Details:
      </Typography>
      {shipment.shipper ? (
        <CompanyInfoBox onClick={() => setSelectedCompany(shipment.shipper)}>
          <Avatar src={shipment.shipper.logoUrl || "/default-logo.png"} />
          <Typography>{shipment.shipper.companyName}</Typography>
        </CompanyInfoBox>
      ) : (
        <Typography>No shipper details available.</Typography>
      )}

      <Divider />
      <Typography variant="h6" gutterBottom>
        Delivery Company Details:
      </Typography>
      {shipment.deliveryCompany ? (
        <CompanyInfoBox
          onClick={() => setSelectedCompany(shipment.deliveryCompany)}
        >
          <Avatar
            src={shipment.deliveryCompany.logoUrl || "/default-logo.png"}
          />
          <Typography>{shipment.deliveryCompany.companyName}</Typography>
        </CompanyInfoBox>
      ) : (
        <Typography>No delivery company details available.</Typography>
      )}

      <DetailRow>
        {renderDetails(
          "Shipment Number",
          shipment.shipmentNumber,
          faClipboardCheck
        )}
        {renderDetails("Description", shipment.description, faInfoCircle)}
        {renderDetails("Weight", `${shipment.weight} lbs`, faWeightHanging)}
        {renderDetails("Price Range", shipment.priceRange, faFileInvoiceDollar)}
        {renderDetails("Truck Type", shipment.truckType, faTruck)}
        {renderDetails("Truck Size", `${shipment.truckSize} ft`, faCogs)}
        {renderDetails(
          "Shipment Quantity",
          shipment.shipmentQuantity,
          faListAlt
        )}
        {renderDetails("Total Miles", `${shipment.totalMiles} miles`, faRoute)}
        {renderDetails(
          "Cost Per Mile",
          `$${shipment.costPerMile}`,
          faDollarSign
        )}
        {renderDetails("Total Cost", `$${shipment.totalCost}`, faBalanceScale)}
        {renderDetails(
          "Is Round Trip",
          shipment.isRoundTrip ? "Yes" : "No",
          faSyncAlt
        )}
        {renderDetails("Status", shipment.status, faClipboardCheck)}
        {renderDetails(
          "Pickup Date",
          shipment.pickupTime
            ? new Date(shipment.pickupTime).toLocaleDateString()
            : "N/A",
          faCalendarAlt
        )}
        {renderDetails(
          "Drop-off Date",
          shipment.dropOffTime
            ? new Date(shipment.dropOffTime).toLocaleDateString()
            : "N/A",
          faCalendarAlt
        )}
      </DetailRow>

      <Modal
        open={!!selectedCompany}
        onClose={() => setSelectedCompany(null)}
        aria-labelledby="company-modal"
      >
        <ModalContent>
          {selectedCompany ? (
            <>
              {renderCompanyInfo(selectedCompany)}
              <CloseButton onClick={() => setSelectedCompany(null)}>
                Close
              </CloseButton>
            </>
          ) : (
            <Typography>Loading company details...</Typography>
          )}
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ShipmentDetailsSection;

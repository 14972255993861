import React from "react";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";
import CompanyStat from "./CompanyStat";
import AvatarWrapper from "./AvatarWrapper";
import "./CompanyList.styles.css";

const CompanyList = ({ companies, onSelect }) => {
  return (
    <div className="scrollable-container">
      {companies.length === 0 ? (
        <Typography variant="h6" className="no-companies-text">
          No companies available at the moment.
        </Typography>
      ) : (
        companies.map((company) => (
          <motion.div
            key={company._id}
            className="company-card"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
            onClick={() => onSelect(company)}
          >
            <AvatarWrapper
              src={company.logoUrl || "/default-logo.png"}
              companyName={company.companyName}
            />
            <Typography variant="h6" className="company-name">
              {company.companyName}
            </Typography>
            <div className="stat-row">
              <CompanyStat
                icon="faStar"
                label={
                  company.reviews?.length
                    ? (
                        company.reviews.reduce((sum, r) => sum + r.rating, 0) /
                        company.reviews.length
                      ).toFixed(1)
                    : "No Reviews"
                }
              />
              <CompanyStat
                icon="faTruck"
                label={`Trucks: ${company.trucks?.length || 0}`}
              />
              <CompanyStat
                icon="faBox"
                label={`Shipments: ${company.finishedShipmentsCount || 0}`}
              />
            </div>
          </motion.div>
        ))
      )}
    </div>
  );
};

export default CompanyList;

import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
  useContext,useState
} from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {
  getStoredToken,
  setStoredToken,
  removeStoredToken,
  getStoredRefreshToken,
  setStoredRefreshToken,
  removeStoredRefreshToken,
  getStoredCompany,
  setStoredCompany,
  removeStoredCompany,
} from "../LocalStorageManager/LocalStorageManager";
import { enqueueSnackbar } from "notistack";

const ActionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};

const initialState = {
  company: getStoredCompany(),
  loading: true,
  error: null,
  enqueueSnackbar,
};

function authReducer(state, action) {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      console.log("[AuthProvider] Login successful:", action.payload.company);
      return {
        ...state,
        company: action.payload.company,
        loading: false,
        error: null,
      };
    case ActionTypes.LOGOUT:
      console.log("[AuthProvider] Logged out.");
      return { ...initialState, loading: false };
    case ActionTypes.SET_LOADING:
      console.log("[AuthProvider] Loading state:", action.payload);
      return { ...state, loading: action.payload };
    case ActionTypes.SET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };

    case ActionTypes.SET_ERROR:
      console.error("[AuthProvider] Error:", action.payload);
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
const [availableTrucks, setAvailableTrucks] = useState([]);
const [availableDrivers, setAvailableDrivers] = useState([]);
const [loading, setLoading] = useState(false);

  const clearAuthData = useCallback(() => {
    console.log("[AuthProvider] Clearing authentication data.");
    removeStoredToken();
    removeStoredRefreshToken();
    removeStoredCompany();
  }, []);

  const initializeAuth = useCallback(async () => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: true });

    const token = getStoredToken();
    const refreshToken = getStoredRefreshToken();
    const company = getStoredCompany();

    if (!token || !refreshToken || !company) {
      console.warn("[AuthProvider] Missing token or company data.");
      clearAuthData();
      dispatch({ type: ActionTypes.LOGOUT });
      return;
    }

    try {
      const response = await axiosInstance.get("/auth/validate-token", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.data.valid) {
        throw new Error("Invalid or expired token.");
      }

      console.log("[AuthProvider] Token validated successfully.");
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: { company },
      });
    } catch (error) {
      console.warn(
        "[AuthProvider] Token validation failed. Attempting refresh."
      );
      try {
        const refreshResponse = await axiosInstance.post("/auth/token", {
          refreshToken,
        });
        const { accessToken } = refreshResponse.data;

        setStoredToken(accessToken);

        console.log("[AuthProvider] Token refreshed successfully.");
        dispatch({
          type: ActionTypes.LOGIN_SUCCESS,
          payload: { company },
        });
      } catch (refreshError) {
        console.error(
          "[AuthProvider] Token refresh failed:",
          refreshError.message
        );
        clearAuthData();
        dispatch({ type: ActionTypes.LOGOUT });
        navigate("/login");
      }
    } finally {
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    }
  }, [clearAuthData, navigate]);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  const handleLogin = async (formData) => {
    console.log("[AuthProvider] Attempting login with data:", formData);

    try {
      const response = await axiosInstance.post("/auth/login", formData);
      const { accessToken, refreshToken, company } = response.data;

      setStoredToken(accessToken);
      setStoredRefreshToken(refreshToken);
      setStoredCompany(company);

      console.log("[AuthProvider] Login successful for company:", company);
      dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: { company } });
      navigate("/loadboard");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Login failed. Please try again.";
      console.error("[AuthProvider] Login error:", errorMessage);

      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: errorMessage,
      });
    }
  };

  const handleLogout = useCallback(() => {
    console.log("[AuthProvider] Logging out.");
    clearAuthData();
    dispatch({ type: ActionTypes.LOGOUT });
    navigate("/login", { replace: true });
  }, [clearAuthData, navigate]);

  const handleCompanyRegister = async (formData) => {
    console.log("[AuthProvider] Attempting registration with data:", formData);

    dispatch({ type: ActionTypes.SET_LOADING, payload: true });

    try {
      const response = await axiosInstance.post("/auth/register", formData);
      const { accessToken, refreshToken, company } = response.data;

      setStoredToken(accessToken);
      setStoredRefreshToken(refreshToken);
      setStoredCompany(company);

      console.log(
        "[AuthProvider] Registration successful for company:",
        company
      );
      dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: { company } });
      navigate("/loadboard");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Registration failed. Please try again.";
      console.error("[AuthProvider] Registration error:", errorMessage);

      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: errorMessage,
      });
    } finally {
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    }
  };

  
const fetchAllShipments = useCallback(async () => {
  console.log("[CLIENT] Fetching all shipments...");

  try {
    const { data } = await axiosInstance.get("/shipment/all");

    if (data?.success && Array.isArray(data.shipments)) {
      console.log(
        `[CLIENT] Successfully fetched ${data.shipments.length} shipments.`
      );

      return data.shipments.map((shipment) => ({
        ...shipment,
        assignedTruck: shipment.assignedTruck || "Not Assigned",
        assignedDriver: shipment.assignedDriver || "Not Assigned",
      }));
    }

    console.warn("[CLIENT] No shipments found in response.");
    return [];
  } catch (error) {
    if (error.response?.status === 404) {
      console.warn("[CLIENT] No shipments found. Server returned 404.");
      return [];
    }

    console.error("[CLIENT] Error fetching shipments:", error.message);
    return [];
  }
}, []);





 const fetchCompanyDetails = useCallback(async (companyId) => {
   if (!companyId || typeof companyId !== "string") {
     console.warn("[CLIENT] Invalid companyId provided:", companyId);
     return null;
   }

   try {
     console.log(`[CLIENT] Fetching details for company ID: ${companyId}`);
     const { data } = await axiosInstance.get(
       `/company/companies/${companyId}`
     );
     if (data?.success) {
       console.log(
         "[CLIENT] Company details fetched successfully:",
         data.company
       );
       return data.company;
     } else {
       console.error("[CLIENT] Failed to fetch company details:", data.error);
       return null;
     }
   } catch (error) {
     console.error(
       `[CLIENT] Error fetching company details for ID ${companyId}:`,
       error.message
     );
     return null;
   }
 }, []);

const fetchResources = useCallback(async () => {
  if (!state.company?.id) {
    enqueueSnackbar("Company ID is missing. Unable to fetch resources.", {
      variant: "warning",
    });
    return null;
  }

  setLoading(true);

  try {
    const { data } = await axiosInstance.get(
      `/company/${state.company.id}/available-resources`
    );

    console.log("[CLIENT] Raw data received from server:", data);

    if (data?.success && data.availableResources) {
      const {
        allTrucks = [],
        allDrivers = [],
        shipmentsByStatus = { available: [], inTransit: [], completed: [] },
      } = data.availableResources;

      // Debugging: Check allTrucks for assignedDriver presence
      allTrucks.forEach((truck, index) => {
        if (!truck.assignedDriver) {
          console.warn(
            `[CLIENT] Truck at index ${index} missing assignedDriver:`,
            truck
          );
        } else {
          console.log(
            `[CLIENT] Truck at index ${index} has assignedDriver:`,
            truck.assignedDriver
          );
        }
      });

      dispatch({
        type: ActionTypes.SET_RESOURCES,
        payload: {
          allTrucks,
          allDrivers,
          shipmentsByStatus,
        },
      });

      return { allTrucks, allDrivers, shipmentsByStatus };
    } else {
      enqueueSnackbar("No resources found. Please try again later.", {
        variant: "error",
      });
      return null;
    }
  } catch (error) {
    console.error("[CLIENT] Error fetching resources:", error.message);
    enqueueSnackbar("Failed to load resources. Please try again.", {
      variant: "error",
    });
    return null;
  } finally {
    setLoading(false);
  }
}, [state.company?.id, dispatch, enqueueSnackbar]);


const handleCompleteShipment = useCallback(
  async (shipment) => {
    try {
      console.log("[CLIENT] Completing shipment:", shipment);
      await axiosInstance.patch(`/company/${shipment._id}/complete`);
      enqueueSnackbar("Shipment completed successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("[CLIENT] Error completing shipment:", error.message);
      enqueueSnackbar("Failed to complete shipment.", { variant: "error" });
    }
  },
  [enqueueSnackbar]
);

  return (
    <AuthContext.Provider
      value={{
        company: state.company,
        isAuthenticated: !!state.company,
        loading: state.loading,
        error: state.error,
        handleLogin,
        handleLogout,
        fetchCompanyDetails,
        fetchResources,
        handleCompleteShipment,
        handleCompanyRegister,
        fetchAllShipments,
      }}
    >
      {state.loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => useContext(AuthContext);









// const handleDeleteShipment = useCallback(
//   async (shipmentId) => {
//     if (!shipmentId) {
//       enqueueSnackbar("Invalid shipment ID.", { variant: "warning" });
//       return false;
//     }

//     try {
//       const { data } = await axiosInstance.delete(`/company/${shipmentId}`);
//       if (data?.success) {
//         enqueueSnackbar("Shipment deleted successfully.", {
//           variant: "success",
//         });
//         return true;
//       }
//       enqueueSnackbar(data?.error || "Failed to delete shipment.", {
//         variant: "error",
//       });
//     } catch (error) {
//       enqueueSnackbar("Error deleting shipment.", { variant: "error" });
//     }
//     return false;
//   },
//   [enqueueSnackbar]
// );


import React, { useState, useEffect, useCallback } from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import PostShipment from "../PostShipment";
import ViewShipments from "../ViewShipments";
import Dashboard from "../Dashboard";
import Companies from "../ShippingCompany/Companies";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";

const ShipmentComponent = () => {
  const { fetchResources, company } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState(
    () => parseInt(localStorage.getItem("activeTab")) || 0
  );
  const [localResources, setLocalResources] = useState({
    companyId: company?.id || "",
    allTrucks: [],
    allDrivers: [],
  });
  const [loading, setLoading] = useState(false);

  const fetchAllResources = useCallback(async () => {
    if (!company?.id) {
      enqueueSnackbar("Company information is missing.", { variant: "error" });
      return;
    }

    try {
      setLoading(true);
      const resources = await fetchResources();
      if (resources) {
        setLocalResources({
          companyId: company.id,
          allTrucks: resources.allTrucks || [],
          allDrivers: resources.allDrivers || [],
        });
      }
    } catch (error) {
      console.error("Error fetching resources:", error.message);
      enqueueSnackbar("Failed to load resources.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [company?.id, fetchResources, enqueueSnackbar]);

  useEffect(() => {
    fetchAllResources();
  }, [fetchAllResources]);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem("activeTab", newValue);
  };

  const tabs = [
    { label: "Post Shipment", component: <PostShipment /> },
    {
      label: "View Shipments",
      component: (
        <ViewShipments
          localResources={localResources}
          fetchAllResources={fetchAllResources}
        />
      ),
    },
    { label: "Dashboard", component: <Dashboard resources={localResources} /> },
    { label: "Companies", component: <Companies /> },
  ];

  return (
    <>
      <AppBar position="static">
        <Tabs value={activeTab} onChange={handleTabChange}>
          {tabs.map((tab, idx) => (
            <Tab key={idx} label={tab.label} />
          ))}
        </Tabs>
      </AppBar>
      <Box sx={{ padding: 2 }}>
        {loading ? "Loading..." : tabs[activeTab]?.component}
      </Box>
    </>
  );
};

export default ShipmentComponent;

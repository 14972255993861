import React from "react";
import { Routes, Route } from "react-router-dom";
import HeaderNavbar from "./components/HeaderNavbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import LoadBoard from "./pages/LoadBoard";
import Footer from "./components/Footer";
import Tracking from "./pages/Tracking";
import Registration from "./components/Auth/Registration";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard";
import PostShipment from "./components/PostShipment";
import ViewShipments from "./components/ViewShipments";
import "./App.css";
import Companies from "./components/ShippingCompany/Companies";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack"; // Import SnackbarProvider

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Blue
    },
    secondary: {
      main: "#dc004e", // Red
    },
  },
});

const App = () => {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        {/* Wrap your app with SnackbarProvider */}
        <SnackbarProvider maxSnack={3}>
          <HeaderNavbar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/service" element={<Services />} />
              <Route path="/tracking" element={<Tracking />} />
              <Route path="/loadboard" element={<LoadBoard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/post-shipment" element={<PostShipment />} />
              <Route path="/view-shipments" element={<ViewShipments />} />
              <Route path="/comp" element={<Companies />} />
            </Routes>
          </main>
          <Footer />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAuth } from "../AuthContext/AuthContext";
import CompanyList from "./CompanyList";
import CompanyModal from "./CompanyModal";
import "./Companies.styles.css";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { company: reviewerCompany } = useAuth();

  const reviewerCompanyId = reviewerCompany?.id || reviewerCompany?._id;

  // Filter companies based on the opposite type of the reviewer's company
  const filterCompaniesByType = (allCompanies, userCompanyType) => {
    const oppositeType =
      userCompanyType === "Delivery" ? "Shipping" : "Delivery";
    return allCompanies.filter((comp) => comp.companyType === oppositeType);
  };

  // Fetch companies that the reviewer company can interact with
  const fetchCompanies = async () => {
    if (!reviewerCompanyId) {
      console.error("No reviewer company ID found. Skipping fetch.");
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/shipment/companies/oppositeCompanies/${reviewerCompanyId}`
      );
      const allCompanies = response.data.companies || [];
      const filteredCompanies = filterCompaniesByType(
        allCompanies,
        reviewerCompany?.companyType
      );
      setCompanies(filteredCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error.message);
      enqueueSnackbar(
        error.response?.data?.error || "Error fetching companies.",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };

const handleReviewSubmit = async (reviewData) => {
  try {
    const { companyId, rating, comment } = reviewData;

    const payload = { companyId, rating, comment }; 
    console.log("Payload sent to server:", payload);

    const response = await axiosInstance.post(
      `/company/${reviewerCompanyId}/reviews`,
      payload
    );

    console.log("Server response:", response.data);

    setCompanies((prevCompanies) =>
      prevCompanies.map((company) =>
        company._id === companyId
          ? {
              ...company,
              reviews: [...(company.reviews || []), response.data.review],
            }
          : company
      )
    );

    enqueueSnackbar("Review submitted successfully!", { variant: "success" });
  } catch (error) {
    console.error(
      "Error submitting review:",
      error.response?.data || error.message
    );
    enqueueSnackbar(error.response?.data?.error || "Error submitting review.", {
      variant: "error",
    });
  }
};


  const fetchReviews = async (companyId) => {
    try {
      const response = await axiosInstance.get(`/company/${companyId}/reviews`);
      return response.data.reviews || [];
    } catch (error) {
      console.error("Error fetching reviews:", error.message);
      enqueueSnackbar("Failed to load reviews.", { variant: "error" });
      return [];
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [reviewerCompanyId, reviewerCompany?.companyType, enqueueSnackbar]);

  return (
    <Box className="companies-container">
      <Typography variant="h4" className="companies-title">
        {reviewerCompany?.companyType === "Delivery"
          ? "Shipping Companies"
          : "Delivery Companies"}
      </Typography>

      {loading ? (
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      ) : companies.length === 0 ? (
        <Typography>No companies found.</Typography>
      ) : (
        <CompanyList
          companies={companies}
          onSelect={(company) => setSelectedCompany(company)}
        />
      )}

      {selectedCompany && (
        <CompanyModal
          company={selectedCompany}
          onClose={() => setSelectedCompany(null)}
          onSubmitReview={handleReviewSubmit}
          fetchReviews={fetchReviews}
        />
      )}
    </Box>
  );
};

export default Companies;

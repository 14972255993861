import axios from "axios";
import {
  getStoredToken,
  setStoredToken,
  getStoredRefreshToken,
  removeStoredToken,
  removeStoredCompany,
  removeStoredRefreshToken,
} from "./LocalStorageManager/LocalStorageManager";

const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3003";

const axiosInstance = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getStoredToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;

    if (response?.status === 401 && config.url !== "/auth/login") {
      console.warn("Unauthorized. Attempting to refresh token...");
      const refreshToken = getStoredRefreshToken();

      if (!refreshToken) {
        console.warn("No refresh token available. Redirecting to login.");
        removeStoredToken();
        removeStoredCompany();
        removeStoredRefreshToken();
        window.location.href = "/login";
        return Promise.reject(error);
      }

      try {
        const refreshResponse = await axios.post(
          `${baseURL}/auth/token`,
          { refreshToken },
          { headers: { "Content-Type": "application/json" } }
        );

        const { accessToken } = refreshResponse.data;

        setStoredToken(accessToken);

        // Retry the original request with the new token
        config.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstance.request(config);
      } catch (refreshError) {
        console.error(
          "Token refresh failed:",
          refreshError.message || refreshError
        );
        removeStoredToken();
        removeStoredCompany();
        removeStoredRefreshToken();
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

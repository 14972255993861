import styled from "styled-components";
import { Box, Button } from "@mui/material";

export const StyledModal = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1200;
  padding: 20px;
`;

export const ModalContent = styled(Box)`
  max-width: 600px;
  width: 100%;
  padding: 25px 30px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const ReviewsContainer = styled(Box)`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 15px;
  border: 1px solid #e6e6e6;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;

export const ReviewBox = styled(Box)`
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const SubmitButton = styled(Button)`
  margin-top: 15px;
  text-transform: capitalize;
  background-color: #007bff;

  &:hover {
    background-color: #0056b3;
  }
`;

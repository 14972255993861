import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import ShipmentDetailsSection from "./ShipmentDetailsSection";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogWrapper = styled(motion.div)`
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.bgColor || "#3f51b5"};
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.hoverBg || "#303f9f"};
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`;

const ShipmentDetailsDialog = ({
  open,
  onClose,
  shipment,
  onPickup,
  onComplete,
  onSubmitOffer,
  loadingAction = false,
  availableTrucks = [],
  availableDrivers = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTruck, setSelectedTruck] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [offerAmount, setOfferAmount] = useState("");

  useEffect(() => {
    if (shipment?.status === "Available") {
      setSelectedTruck(availableTrucks[0]?._id || "");
      setSelectedDriver(availableDrivers[0]?._id || "");
    }
  }, [shipment, availableTrucks, availableDrivers]);

const handlePickup = async () => {
  console.log("[CLIENT] Attempting to pick up shipment with details:", {
    selectedTruck,
    selectedDriver,
    shipmentId: shipment?._id,
    shipmentDetails: shipment,
  });

  if (!selectedTruck || !selectedDriver) {
    console.warn("[CLIENT] Missing truck or driver selection for pickup.");
    enqueueSnackbar("Please select both a truck and a driver.", {
      variant: "warning",
    });
    return;
  }

  try {
    await onPickup(selectedTruck, selectedDriver);
    console.log("[CLIENT] Pickup process initiated successfully.");
  } catch (error) {
    console.error("[CLIENT] Error during pickup process:", error.message);
    enqueueSnackbar("Error during pickup process. Please try again.", {
      variant: "error",
    });
  }
};



const handleCompleteShipment = async () => {
  try {
    console.log("[CLIENT] Initiating shipment completion for:", shipment);
    await onComplete(shipment);
    console.log("[CLIENT] Shipment completion successful:", shipment);
    enqueueSnackbar("Shipment completed successfully.", {
      variant: "success",
    });
    onClose(); 
  } catch (error) {
    console.error("[CLIENT] Error during shipment completion:", error.message);
    enqueueSnackbar("Failed to complete shipment. Please try again.", {
      variant: "error",
    });
  }
};


  const handleSubmitOffer = async () => {
    if (!offerAmount || isNaN(offerAmount) || offerAmount <= 0) {
      enqueueSnackbar("Enter a valid offer amount.", { variant: "warning" });
      return;
    }
    await onSubmitOffer(offerAmount);
    setOfferAmount("");
  };

  return open ? (
    <Overlay
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <DialogWrapper
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      >
        <h2>Shipment Details</h2>
        <ShipmentDetailsSection shipment={shipment} />

        {shipment?.status === "Available" && (
          <>
            <label>Select a Truck:</label>
            <select
              value={selectedTruck}
              onChange={(e) => setSelectedTruck(e.target.value)}
            >
              <option value="" disabled>
                Select a truck
              </option>
              {availableTrucks.map((truck) => (
                <option key={truck._id} value={truck._id}>
                  {truck.make} ({truck.licensePlate})
                </option>
              ))}
            </select>

            <label>Select a Driver:</label>
            <select
              value={selectedDriver}
              onChange={(e) => setSelectedDriver(e.target.value)}
            >
              <option value="" disabled>
                Select a driver
              </option>
              {availableDrivers.map((driver) => (
                <option key={driver._id} value={driver._id}>
                  {driver.fullName}
                </option>
              ))}
            </select>

            <ButtonGroup>
              <StyledButton onClick={handlePickup} disabled={loadingAction}>
                Confirm Pickup
              </StyledButton>
              <StyledButton
                onClick={handleSubmitOffer}
                disabled={loadingAction}
              >
                Submit Offer
              </StyledButton>
            </ButtonGroup>
          </>
        )}

        {shipment?.status === "In Transit" && (
          <ButtonGroup>
            <StyledButton
              onClick={handleCompleteShipment}
              disabled={loadingAction}
            >
              Complete Shipment
            </StyledButton>
          </ButtonGroup>
        )}

        <ButtonGroup>
          <StyledButton onClick={onClose} disabled={loadingAction}>
            Close
          </StyledButton>
        </ButtonGroup>
      </DialogWrapper>
    </Overlay>
  ) : null;
};

export default ShipmentDetailsDialog;
